html,
body {
  position: fixed;
  overflow: hidden;
}

div#root {
  width: 100vw;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  } to {
      transform: rotate(360deg);
    }
}
