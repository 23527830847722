.billPeriod .DateRangePickerInput {
  display: flex;
  background-color: inherit;
}
.billPeriod .DateInput_input__small {
  position: relative;
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background-color: inherit;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.2px;
  padding: 7px 7px 5px;
  color: #424242;
  border-bottom: 0;
}
.billPeriod .DateInput__small {
  width: auto;
}
.billPeriod .DateInput {
  text-align: center;
  background: unset;
  margin-top: -5px;
}
.billPeriod .DateRangePicker_picker {
  z-index: 1000;
  background-color: #fff;
  position: absolute;
}
.billPeriod .CalendarDay__selected:active,
.billPeriod .CalendarDay__selected:hover {
  background: #115293;
  border: 1px double #115293;
  color: #fff;
}

.billPeriod .DateRangePickerInput__withBorder {
  border: 0;
}

.billPeriod .CalendarDay__selected_span {
  background: #1976d2;
  color: white;
  border: 1px double #115293;
}

.billPeriod .CalendarDay__hovered_span:hover,
.billPeriod .CalendarDay__hovered_span {
  background: #1976d2;
  border: #1976d2;
  color: #115293;
}

.billPeriod .CalendarDay__selected {
  background: #115293;
  color: white;
  border: 1px double #115293;
}
.billPeriod .CalendarDay__selected_span:hover {
  background-color: #115293;
}
.billPeriod .DateInput_input__focused {
  border-bottom: 2px solid #3758fb;
}

.billPeriod .DateRangePickerInput_arrow {
  padding-right: 0px;
}
